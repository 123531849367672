.mainCat{
    height: 200px;
    width: 350px;
    position: relative;
}

.cat{
    background-color: #f19b1a;
    height: 65px;
    width: 80px;
    border-radius: 0 80px 80px 0;
    position: absolute;
    bottom: 60px;
    right: 50px;
}
.cat-ear{
    height: 15px;
    width: 15px;
    background-color: #f19b1a;
    position: absolute;
    bottom: 64px;
    left: 8px;
    border-radius: 20px 0 0 0;
    box-shadow: 25px 0 #f19b1a;
}

.cat-eye,
.cat-eye:before{
    height: 7px;
    width: 11px;
    border: 3px solid #2c2c2c;
    position: absolute;
    border-radius: 0 0 15px 15px ;
    border-top:none;
}

.cat-eye{
    top: 18px;
    left: 15px;
}

.cat-eye::before{
    content: "";
    left: 30px;
}

.cat-nose{
    background-color: #ffffff;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: 32px;
    left: 25px;
    box-shadow: 12px 0 #ffffff;
}

.cat-nose:before{
    content: "";
    width: 12px;
    height: 8px;
    position: absolute;
    background-color: #ffffff;
    left: 6px;
}
.cat-nose:after{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-top: 8px solid #ef926b;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 7px;
    left: 6px;
}
.cat-mouth{
    background-color: #2c2c2c;
    height: 15px;
    width: 17px;
    position: absolute;
    border-radius: 0 0 5px 5px;
    top: 38px;
    left: 28px;
    animation:  mouth-move 2s infinite;
    transform-origin: top;
}

@keyframes mouth-move {
    50%{
        transform: scaleY(0.6);
    }
}

.cat-body{
    background-color: #f19b1a;
    height: 90px;
    width: 140px;
    position: absolute;
    right: 65px;
    bottom: 0;
    border-radius: 60px 60px 0 0 ;
    animation: sleep 2s infinite;
    transform-origin: bottom right;
}
@keyframes sleep {
    50%{
        transform: scale(0.9, 1.05);
    }
}

.cat-tail{
    background-color: #d07219;
    height: 20px;
    width: 100px;
    position: absolute;
    right: 150px;
    bottom: 0;
    border-radius: 20px 0 0 20px;
}

.cat-body::before{
    content: "";
    position: absolute;
    background-color: #ffffff;
    height: 12px;
    width: 30px;
    border-radius: 6px;
    bottom: 0;
    left: 22px;
    box-shadow: 45px 0 #ffffff;
}