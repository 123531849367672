@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@451&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212 !important;
  /*background-image: linear-gradient(to left, #1d1d1d, #1d1d1d); */
}
.sticky {
  background-color: #121212 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px #121212 !important;
  -webkit-backdrop-filter: blur(15px) !important;
          backdrop-filter: blur(15px) !important;
}
h2 {
  color: whitesmoke;
}
.purple {
  color: #be6adf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/*NAVBAR */
.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}
.navbar-toggler {
  padding: 0.25rem 1.5rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.navbar img {
  width: 80px;
  margin-top: 10px;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be6adf !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
  color: #121212;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  -webkit-transform: rotate(45deg) !important;
          transform: rotate(45deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  -webkit-transform: rotate(-45deg) !important;
          transform: rotate(-45deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #121212 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: #be6adf !important;
}
.navbar-nav .nav-link {
  color: whitesmoke !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}


.nav-link {
  padding: 0.8rem 1rem !important;
  margin: 0.8rem;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}
.navbar-nav button{
  margin: 0.9rem;
  color: whitesmoke !important;
  border: solid 2px #be6adf;
  padding: 0.6rem 1rem;
  background-color: #121212;
  text-decoration: none;
  border-radius: 4px;
}
.navbar-nav button:hover {
  color: whitesmoke;
  background-color: #be6adf50;
}


.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #be6adf;
  bottom: 1px;
  left: 50%;
  z-index: -1;
  transition: all 0.2s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
  left: 0;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #121212;
}

::-webkit-scrollbar-thumb {
  background: #7e4794;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #be6adf;
  border-radius: 100px;
}

/* HOME */

@media (min-width: 768px) {
  h1 .name {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  h1 .name {
    font-size: 5rem;
  }
}

h1 .name {
  color: #be6adf;
}
.main-header {
  padding-top: 80px;
}

.intro-main {
  padding: 9rem 0 2rem !important;
  text-align: left;
  height: 95vh;
  color: whitesmoke;
}
.error {
  display: inline-block;
  padding: 9rem 0 2rem !important;
  text-align: center;
  height: 95vh;
  color: whitesmoke;
  vertical-align: middle;
}
.error h1 {
  font-weight: 600;
  font-size: clamp(100px, 25vw, 200px);
}
.error h2 {
  font-size: clamp(30px, 5vw, 50px);
  font-weight: 300;
  margin-bottom: 50px;
}
.error a {
  color: whitesmoke;
  border: solid 2px #be6adf;
  padding: 10px;
  background-color: transparent;
  text-decoration: none;
  border-radius: 4px;
}
.error a:hover {
  color: whitesmoke;
  background-color: #be6adf50;
}
.main {
  position: relative;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}
/* DOG CAT */
.dogCat {
  position: relative;
  min-height: 60vh;
  color: whitesmoke;
  display: inline-block;
  padding: 2rem 20rem 9rem !important;
  text-align: center;
  color: whitesmoke;
  vertical-align: middle;
}
@media (max-width: 1366px) {
  .dogCat {
    padding: 0rem 8rem !important;
  }
}

@media (max-width: 700px) {
  .dogCat {
    padding: 0rem 0rem !important;
  }
}
.dogCatButton{
  background-color: #121212;
  border: 2px solid #be6adf;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  font-size: 40px;
  margin: 15px;
}
.dogCatButton:hover {
  color: whitesmoke;
  background-color: #be6adf50;
  border: none;
}
.dogCatButton:focus {
  background-color: #121212;
  border: 2px solid #be6adf;
  box-shadow: none;
}
.dogCatButton:focus-visible {
  background-color: #121212;
  border: 2px solid #be6adf;
  box-shadow: none;
}
#dogCatSelected
{
  background-color: #be6adf50;
  border: none;
  box-shadow: none;
}
.catDogAnimationContainer{
  min-height: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* FOOTER */
.footer {
  color: whitesmoke;
  height: 5vh;
}
.rowFooter {
  height: 90% !important;
}
.signature {
  padding-bottom: 20px;
}
/* Animaciones Letras */
span.name {
  display: inline-block;
  white-space: pre;
  transition: 0.1s;
}
span.name:hover {
  color: whitesmoke;
}
.animada {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-name: rebote;
          animation-name: rebote;
}

@-webkit-keyframes rebote {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes rebote {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

canvas {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

/* NICE COLOR 0c0513 */
/* LOADER */
#load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #121212;
  background-repeat: no-repeat;
  background-position: center;
}

#noLoad {
  opacity: 0;
  display: none;
}

#noScroller {
  overflow: hidden;
  height: 100vh;
}

/* ABOUT SECTION */
.homeAbout {
  position: relative;
  min-height: 90vh;
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.aboutContent {
  color: whitesmoke !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  text-align: justify;
}

.techDisplay {
  color: whitesmoke;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 60px;
}
.techRow {
  -webkit-justify-content: center;
          justify-content: center;
}
.techHolder {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  border: solid 2px #be6adf !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  transition: all 0.4s !important;
  width: 25% !important;
  height: 135px;
}
.techHolder:hover {
  border-color: whitesmoke !important;
}

.bodyAbout {
  font-size: 1.3em;
}

@media (max-width: 1366px) {
  .techHolder {
    width: 40% !important;
  }
}

@media (max-width: 700px) {
  .techHolder {
    width: 1000% !important;
  }
}

/* PROJECTS */
.homeProjects{
  position: relative;
  min-height: 70vh;
  /*border: solid 1px #7e4794; */
  padding: 1rem 20rem 9rem !important;
}
.workCards
{
  color: whitesmoke;
  min-height: 50vh;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding: 1vw;
}
@media screen and (max-width:767px){
  .workCards{
    display: none;
  }
}
.workCards .WorkContainer{
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  border: solid 1px #be6adf;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}
.workCards .WorkContainer:hover{
  -webkit-flex: 2.5 1;
          flex: 2.5 1;
}
.workSection{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: all .5s;
}
.workCards .WorkContainer:hover .workSection{
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.workTitle
{
  min-width: 14em;
  padding: .5em;
  text-align: center;
  transition: all .5s;
  text-transform:uppercase;
  letter-spacing: .1em;
}
.workListBullets
{
  list-style: none;
  color: #C4C4C4;
}
.workListBullets li::before {
  content: "\2022";
  color: #be6adf;
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em;
}
@media(max-width:767px) {
  .columnaTrabajoDetalle{
    padding-top: 2vh;
  }
}
@media (max-width: 1366px) {
  .homeProjects {
    padding: 0rem 8rem !important;
  }
}
@media(max-width:767px)
{
  .projectListRow{
    display: block;
    white-space: nowrap;
    overflow-x: auto !important;
    padding-bottom: 5px;
  }
  .navItemProjectsList{
    display: inline-block;
  }
  .nav-link.active.projectsList{
    display: inline-block;
    background-color: #332F2E!important ;
    border-bottom: solid 4px #7e4794 !important;
    border-left: none !important;
    color: #be6adf;
  }
  .nav-link.projectsList{
    display: inline-block;
    background-color: #121212 ;
    color : whitesmoke;
    border-bottom: solid 3px #464646;
    border-left: none !important;
    border-radius: 0% !important;
    margin: 0%;
  }
}

@media (max-width: 700px) {
  .homeProjects {
    padding: 0rem 0rem !important;
  }
}
@media (min-width:768px){
  .projectsContent{
    display: none;
  }
}
.projectsContent {
  color: whitesmoke !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  text-align: center;
  width: 100%;
}
.bodyProjects{
  font-size: 1.3em;
}
.nav-link.active.projectsList{
  background-color: #332F2E!important ;
  border-left: solid 4px #7e4794;
  color: #be6adf;
}
.nav-link.projectsList{
  background-color: #121212 ;
  color : whitesmoke;
  border-left: solid 3px #464646;
  border-radius: 0% !important;
  margin: 0%;
}
.nav-link.projectsList:hover{
  background-color: #332F2E ;
  color : #be6adf;
  border-left: solid 3px #464646;
  border-radius: 0% !important;
  margin: 0%;
}

.jobInfoContainer{
  width:100%;
  text-align: justify;
}


/* CONTACT */
.contactMain {
  position: relative;
  min-height: 60vh;
  color: whitesmoke;
  display: inline-block;
  padding: 2rem 20rem 9rem !important;
  text-align: center;
  color: whitesmoke;
  vertical-align: middle;
}
@media (max-width: 1366px) {
  .contactMain {
    padding: 0rem 8rem !important;
  }
}

@media (max-width: 700px) {
  .contactMain {
    padding: 0rem 0rem !important;
  }
}

.titleContact {
  margin-bottom: 3rem;
}
.lastButton {
  margin-bottom: 5rem;
}

.contactBut {
  margin-top: 3rem;
  color: whitesmoke !important;
  border: solid 2px #be6adf;
  padding: 1rem 1.5rem;
  background-color: #121212;
  text-decoration: none;
  border-radius: 4px;
}
.bodyContact {
  font-size: 1.2em;
}
.contactBut:hover {
  color: whitesmoke;
  background-color: #be6adf50;
}


.pageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-align-content: center;
          align-content: center;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.cardContainer {
  height: 90%;
  width: 85%;
  margin: auto;
  padding: 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.pageContainer .cardContainer.flipped .front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.pageContainer .cardContainer.flipped .back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.pageContainer .cardContainer .front,
.pageContainer .cardContainer .back {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: block;
  background: #121212;
  padding: 10px;
  position: absolute;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: -webkit-transform ease 500ms;
  transition: transform ease 500ms;
  transition: transform ease 500ms, -webkit-transform ease 500ms;
}

.pageContainer .cardContainer .front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.pageContainer .cardContainer .back {
  background: #121212;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  font-size: 20px;
  top:30%;
}

.contenedorScroll
{
    position:absolute;
    left:50%;
    bottom:0%;
    -webkit-align-items: center;
            align-items: center;
}

.mouse {
    background: #be6adf linear-gradient(transparent 0%, transparent 50%, whitesmoke 50%, whitesmoke 100%);
    position: relative;
    left: calc(50% - 20px) ;
    width: 40px;
    height: 56px;
    border-radius: 100px;
    background-size: 100% 200%;
    -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
            animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}
.mouse:before, .mouse:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.mouse:before {
    width: 34px;
    height: 50px;
    background-color: #121212;
    border-radius: 100px;
}
.mouse:after {
    background-color: whitesmoke;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    -webkit-animation: trackBallSlide 5s linear infinite;
            animation: trackBallSlide 5s linear infinite;
}

p.scroll
{
    color:white;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif !important;
}

@media (max-width: 1200px) {
    .contenedorScroll
    {
       left: calc(50% - 40px);
       bottom: calc(0% + 100px);
    }
}
@-webkit-keyframes colorSlide {
    0% {
        background-position: 0% 100%;
   }
    20% {
        background-position: 0% 0%;
   }
    21% {
        background-color: #be6adf;
   }
    29.99% {
        background-color: whitesmoke;
        background-position: 0% 0%;
   }
    30% {
        background-color: #be6adf;
        background-position: 0% 100%;
   }
    50% {
        background-position: 0% 0%;
   }
    51% {
        background-color: #be6adf;
   }
    59% {
        background-color: whitesmoke;
        background-position: 0% 0%;
   }
    60% {
        background-color: #be6adf;
        background-position: 0% 100%;
   }
    80% {
        background-position: 0% 0%;
   }
    81% {
        background-color: #be6adf;
   }
    90%, 100% {
        background-color: whitesmoke;
   }
}
@keyframes colorSlide {
    0% {
        background-position: 0% 100%;
   }
    20% {
        background-position: 0% 0%;
   }
    21% {
        background-color: #be6adf;
   }
    29.99% {
        background-color: whitesmoke;
        background-position: 0% 0%;
   }
    30% {
        background-color: #be6adf;
        background-position: 0% 100%;
   }
    50% {
        background-position: 0% 0%;
   }
    51% {
        background-color: #be6adf;
   }
    59% {
        background-color: whitesmoke;
        background-position: 0% 0%;
   }
    60% {
        background-color: #be6adf;
        background-position: 0% 100%;
   }
    80% {
        background-position: 0% 0%;
   }
    81% {
        background-color: #be6adf;
   }
    90%, 100% {
        background-color: whitesmoke;
   }
}
@-webkit-keyframes trackBallSlide {
    0% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    6% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    14% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    15%, 19% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    28%, 29.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    30% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    36% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    44% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    45%, 49% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    58%, 59.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    60% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    66% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    74% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    75%, 79% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    88%, 100% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
}
@keyframes trackBallSlide {
    0% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    6% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    14% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    15%, 19% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    28%, 29.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    30% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    36% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    44% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    45%, 49% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    58%, 59.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    60% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
    66% {
        opacity: 1;
        -webkit-transform: scale(0.9) translateY(3.75px);
                transform: scale(0.9) translateY(3.75px);
   }
    74% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(30px);
                transform: scale(0.4) translateY(30px);
   }
    75%, 79% {
        opacity: 0;
        -webkit-transform: scale(0.4) translateY(-15px);
                transform: scale(0.4) translateY(-15px);
   }
    88%, 100% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-15px);
                transform: scale(1) translateY(-15px);
   }
}
@-webkit-keyframes nudgeMouse {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    20% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    50% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    60% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    80% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    90% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
}
@keyframes nudgeMouse {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    20% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    50% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    60% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    80% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
   }
    90% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
}
@-webkit-keyframes nudgeText {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    20% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    50% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    60% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    80% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    90% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
}
@keyframes nudgeText {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    20% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    50% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    60% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
    80% {
        -webkit-transform: translateY(2px);
                transform: translateY(2px);
   }
    90% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
   }
}

.mainCat{
    height: 200px;
    width: 350px;
    position: relative;
}

.cat{
    background-color: #f19b1a;
    height: 65px;
    width: 80px;
    border-radius: 0 80px 80px 0;
    position: absolute;
    bottom: 60px;
    right: 50px;
}
.cat-ear{
    height: 15px;
    width: 15px;
    background-color: #f19b1a;
    position: absolute;
    bottom: 64px;
    left: 8px;
    border-radius: 20px 0 0 0;
    box-shadow: 25px 0 #f19b1a;
}

.cat-eye,
.cat-eye:before{
    height: 7px;
    width: 11px;
    border: 3px solid #2c2c2c;
    position: absolute;
    border-radius: 0 0 15px 15px ;
    border-top:none;
}

.cat-eye{
    top: 18px;
    left: 15px;
}

.cat-eye::before{
    content: "";
    left: 30px;
}

.cat-nose{
    background-color: #ffffff;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: 32px;
    left: 25px;
    box-shadow: 12px 0 #ffffff;
}

.cat-nose:before{
    content: "";
    width: 12px;
    height: 8px;
    position: absolute;
    background-color: #ffffff;
    left: 6px;
}
.cat-nose:after{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-top: 8px solid #ef926b;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 7px;
    left: 6px;
}
.cat-mouth{
    background-color: #2c2c2c;
    height: 15px;
    width: 17px;
    position: absolute;
    border-radius: 0 0 5px 5px;
    top: 38px;
    left: 28px;
    -webkit-animation:  mouth-move 2s infinite;
            animation:  mouth-move 2s infinite;
    -webkit-transform-origin: top;
            transform-origin: top;
}

@-webkit-keyframes mouth-move {
    50%{
        -webkit-transform: scaleY(0.6);
                transform: scaleY(0.6);
    }
}

@keyframes mouth-move {
    50%{
        -webkit-transform: scaleY(0.6);
                transform: scaleY(0.6);
    }
}

.cat-body{
    background-color: #f19b1a;
    height: 90px;
    width: 140px;
    position: absolute;
    right: 65px;
    bottom: 0;
    border-radius: 60px 60px 0 0 ;
    -webkit-animation: sleep 2s infinite;
            animation: sleep 2s infinite;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
}
@-webkit-keyframes sleep {
    50%{
        -webkit-transform: scale(0.9, 1.05);
                transform: scale(0.9, 1.05);
    }
}
@keyframes sleep {
    50%{
        -webkit-transform: scale(0.9, 1.05);
                transform: scale(0.9, 1.05);
    }
}

.cat-tail{
    background-color: #d07219;
    height: 20px;
    width: 100px;
    position: absolute;
    right: 150px;
    bottom: 0;
    border-radius: 20px 0 0 20px;
}

.cat-body::before{
    content: "";
    position: absolute;
    background-color: #ffffff;
    height: 12px;
    width: 30px;
    border-radius: 6px;
    bottom: 0;
    left: 22px;
    box-shadow: 45px 0 #ffffff;
}
@charset "UTF-8";
:root {
  --color-fur: #ffb141;
  --color-fur-dark: #F5832C;
  --color-spot: #F9584C;
  --color-snout: #544258;
  --color-collar: #3EAB6C;
  --duration: .7s;
  --semi-duration: calc(var(--duration) / 2);
  --easing: cubic-bezier(.5, 0, .5, 1);
  --stagger: calc(-1 * var(--semi-duration) / 2);
}

*, *:before, *:after {
  -webkit-animation-timing-function: cubic-bezier(.5, 0, .5, 1) !important;
  -webkit-animation-timing-function: var(--easing) !important;
          animation-timing-function: cubic-bezier(.5, 0, .5, 1) !important;
          animation-timing-function: var(--easing) !important;
}

[class=dog] {
  z-index: 1;
  height: 300px;
  width: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
[class=dog] * {
  position: absolute;
  will-change: transform;
}

.neck {
  width: 50%;
  height: 100px;
  right: -5px;
  bottom: calc(100% - 10px);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  z-index: 2;
}
.neck > .fur {
  border-bottom-left-radius: 10px;
  height: 100%;
  width: 100%;
}
.neck > .fur:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background-color: #F5832C;
  background-color: var(--color-fur-dark);
  z-index: 1;
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.collar {
  width: calc(100% + 10px);
  height: 15%;
  background-color: #3EAB6C;
  background-color: var(--color-collar);
  left: -5px;
  bottom: 30px;
  border-radius: 5px;
  -webkit-animation: collar calc(.7s / 2) calc(calc(.7s / 2) / 4) infinite;
  -webkit-animation: collar var(--semi-duration) calc(var(--semi-duration) / 4) infinite;
          animation: collar calc(.7s / 2) calc(calc(.7s / 2) / 4) infinite;
          animation: collar var(--semi-duration) calc(var(--semi-duration) / 4) infinite;
  z-index: 2;
}
@-webkit-keyframes collar {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
}
@keyframes collar {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
}
.collar:after {
  content: "";
  width: 18%;
  height: 120%;
  border-radius: 50%;
  background: #544258;
  background: var(--color-snout);
  position: absolute;
  right: 0%;
  top: 110%;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-animation: tag calc(.7s / 2) infinite both;
  -webkit-animation: tag var(--semi-duration) infinite both;
          animation: tag calc(.7s / 2) infinite both;
          animation: tag var(--semi-duration) infinite both;
}
@-webkit-keyframes tag {
  from, to {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  50% {
    -webkit-transform: rotate(-15deg) scaleY(1.5);
            transform: rotate(-15deg) scaleY(1.5);
  }
}
@keyframes tag {
  from, to {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  50% {
    -webkit-transform: rotate(-15deg) scaleY(1.5);
            transform: rotate(-15deg) scaleY(1.5);
  }
}

.head {
  height: 80%;
  width: 145%;
  left: -2%;
  bottom: 50%;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  z-index: 2;
  -webkit-animation: head calc(.7s / 2) calc(calc(.7s / 2) / 4) infinite;
  -webkit-animation: head var(--semi-duration) calc(var(--semi-duration) / 4) infinite;
          animation: head calc(.7s / 2) calc(calc(.7s / 2) / 4) infinite;
          animation: head var(--semi-duration) calc(var(--semi-duration) / 4) infinite;
}
@-webkit-keyframes head {
  from, to {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}
@keyframes head {
  from, to {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}
.head > .fur {
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 80px;
}
.head > .fur:before {
  content: "";
  position: absolute;
  top: -80%;
  left: -30%;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-color: #F9584C;
  background-color: var(--color-spot);
  z-index: 1;
  border-radius: 50%;
}

.snout {
  --color: var(--color-snout);
  width: 25%;
  height: 0;
  padding-top: 25%;
  background-color: var(--color);
  top: 0;
  right: -5px;
  border-bottom-left-radius: 100%;
  z-index: 1;
}

.ears {
  bottom: 40%;
  height: 100%;
  width: 30%;
  left: 0;
  z-index: -1;
}

.ear {
  --color: var(--color-spot);
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  -webkit-animation: ear-front calc(.7s / 2) infinite;
  -webkit-animation: ear-front calc(var(--duration) / 2) infinite;
          animation: ear-front calc(.7s / 2) infinite;
          animation: ear-front calc(var(--duration) / 2) infinite;
}
@-webkit-keyframes ear-front {
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}
@keyframes ear-front {
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}
.ear:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 15px;
  z-index: 2;
  top: -2px;
  right: -7px;
  border-top-left-radius: 100%;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-left: 2px solid #544258;
  border-left: 2px solid var(--color-snout);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-animation: ear-mark calc(.7s / 2) calc(-1 * calc(.7s / 2)) infinite;
  -webkit-animation: ear-mark var(--semi-duration) calc(-1 * var(--semi-duration)) infinite;
          animation: ear-mark calc(.7s / 2) calc(-1 * calc(.7s / 2)) infinite;
          animation: ear-mark var(--semi-duration) calc(-1 * var(--semi-duration)) infinite;
}
@-webkit-keyframes ear-mark {
  from, to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
}
@keyframes ear-mark {
  from, to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
}
.ear > .fur {
  border-top-left-radius: 100%;
  height: 100%;
  width: 100%;
}
.ear:nth-child(2) {
  left: 15px;
  bottom: 5px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  --color: var(--color-fur);
  z-index: -1;
  -webkit-animation: ear-back calc(.7s / 2) infinite both;
  -webkit-animation: ear-back calc(var(--duration) / 2) infinite both;
          animation: ear-back calc(.7s / 2) infinite both;
          animation: ear-back calc(var(--duration) / 2) infinite both;
}
@-webkit-keyframes ear-back {
  from, to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}
@keyframes ear-back {
  from, to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

.eye {
  --size: 6%;
  --color: var(--color-snout);
  width: var(--size);
  height: 0;
  padding: var(--size);
  left: 35%;
  top: 20%;
  border-radius: 50%;
  background-color: var(--color);
  z-index: 1;
}
.eye:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color);
  z-index: 1;
}
.eye:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -50%;
  top: -50%;
  border-top-left-radius: 100%;
  border-width: 2px;
  border-color: var(--color);
  border-style: solid;
  border-bottom-color: #F9584C;
  border-bottom-color: var(--color-spot);
  border-right-color: #F9584C;
  border-right-color: var(--color-spot);
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  z-index: 0;
}

.torso {
  width: 56.6666666667%;
  height: 33.3333333333%;
  -webkit-animation: torso calc(.7s / 2) both infinite;
  -webkit-animation: torso var(--semi-duration) both infinite;
          animation: torso calc(.7s / 2) both infinite;
          animation: torso var(--semi-duration) both infinite;
}
@-webkit-keyframes torso {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
}
@keyframes torso {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
}
.torso > .fur {
  height: 100%;
  width: 185px;
  border-top-left-radius: 40px;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: rotate(-20deg) translateY(10px);
          transform: rotate(-20deg) translateY(10px);
  z-index: 1;
}
.torso > .fur:after {
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 200px 85px;
  border-bottom-right-radius: 100px;
}
.torso > .legs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.torso .spot {
  width: 120px;
  height: 120px;
  bottom: 40px;
  left: -20px;
  border-radius: 50%;
  background-color: #F9584C;
  background-color: var(--color-spot);
  z-index: 1;
  -webkit-animation: spot .7s calc(-1 * calc(.7s / 2)) both infinite;
  -webkit-animation: spot var(--duration) calc(-1 * var(--semi-duration)) both infinite;
          animation: spot .7s calc(-1 * calc(.7s / 2)) both infinite;
          animation: spot var(--duration) calc(-1 * var(--semi-duration)) both infinite;
}
@-webkit-keyframes spot {
  from, 66%, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
@keyframes spot {
  from, 66%, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
.torso .spot:after {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: inherit;
  position: absolute;
  bottom: -10px;
  right: 5px;
}
.torso > .tail {
  --color: var(--color-spot);
  bottom: calc(100% - 15px);
  left: 0;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.tail {
  height: 15px;
  width: 20px;
  background-color: var(--color);
  border-top-right-radius: 10px 100%;
  border-top-left-radius: 10px 100%;
  -webkit-animation: tail calc(.7s / 2) infinite;
  -webkit-animation: tail var(--semi-duration) infinite;
          animation: tail calc(.7s / 2) infinite;
          animation: tail var(--semi-duration) infinite;
}
@-webkit-keyframes tail {
  from, to {
    -webkit-transform: rotate(-45deg) translateX(-15%);
            transform: rotate(-45deg) translateX(-15%);
  }
  50% {
    -webkit-transform: rotate(-25deg) translateX(15%);
            transform: rotate(-25deg) translateX(15%);
  }
}
@keyframes tail {
  from, to {
    -webkit-transform: rotate(-45deg) translateX(-15%);
            transform: rotate(-45deg) translateX(-15%);
  }
  50% {
    -webkit-transform: rotate(-25deg) translateX(15%);
            transform: rotate(-25deg) translateX(15%);
  }
}
.tail > .tail {
  bottom: calc(90%);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
          transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
  -webkit-animation: tail-inner calc(.7s / 2) calc(-1 * calc(.7s / 2) / 2) infinite;
  -webkit-animation: tail-inner var(--semi-duration) var(--stagger) infinite;
          animation: tail-inner calc(.7s / 2) calc(-1 * calc(.7s / 2) / 2) infinite;
          animation: tail-inner var(--semi-duration) var(--stagger) infinite;
}
@-webkit-keyframes tail-inner {
  from, to {
    -webkit-transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
            transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
  }
  50% {
    -webkit-transform: rotate(5deg) translateY(50%) scaleX(0.8) scaleY(0.9);
            transform: rotate(5deg) translateY(50%) scaleX(0.8) scaleY(0.9);
  }
}
@keyframes tail-inner {
  from, to {
    -webkit-transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
            transform: rotate(-10deg) translateY(50%) scaleX(0.8) scaleY(0.9);
  }
  50% {
    -webkit-transform: rotate(5deg) translateY(50%) scaleX(0.8) scaleY(0.9);
            transform: rotate(5deg) translateY(50%) scaleX(0.8) scaleY(0.9);
  }
}

.legs > .leg {
  bottom: 5px;
  --color: var(--color-fur);
}
.legs > .leg:nth-child(1), .legs .leg:nth-child(3) {
  right: 15px;
}
.legs > .leg:nth-child(3), .legs > .leg:nth-child(4) {
  --color: var(--color-fur-dark);
  z-index: -1;
}
.legs > .leg:nth-child(1), .legs .leg:nth-child(4) {
  --delay: 0s;
}
.legs > .leg:nth-child(2), .legs > .leg:nth-child(3) {
  --delay: calc(-1 * var(--duration) / 2 ) ;
}
.legs > .leg:nth-child(2) {
  left: 0;
}
.legs > .leg:nth-child(4) {
  left: 0;
}

.leg {
  position: absolute;
  height: 65px;
  width: 45px;
  background-color: var(--color);
  -webkit-transform-origin: center 5px;
          transform-origin: center 5px;
  -webkit-animation: leg infinite .7s 0s;
  -webkit-animation: leg infinite var(--duration) var(--delay, 0s);
          animation: leg infinite .7s 0s;
          animation: leg infinite var(--duration) var(--delay, 0s);
}
.leg > .leg-inner {
  -webkit-animation: leg-inner infinite .7s calc(var(--delay));
  -webkit-animation: leg-inner infinite var(--duration) calc(var(--delay));
          animation: leg-inner infinite .7s calc(var(--delay));
          animation: leg-inner infinite var(--duration) calc(var(--delay));
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
@-webkit-keyframes leg-inner {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: rotate(70deg);
            transform: rotate(70deg);
  }
  76% {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes leg-inner {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: rotate(70deg);
            transform: rotate(70deg);
  }
  76% {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes leg {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
  }
  66% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
@keyframes leg {
  from, to {
    -webkit-transform: none;
            transform: none;
  }
  33% {
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
  }
  66% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
.leg > .fur {
  width: 144.4444444444%;
  height: 100%;
}
.leg > .fur:after {
  width: 45px;
  bottom: 0;
  top: auto;
  top: initial;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.fur {
  position: absolute;
  overflow: hidden;
}
.fur:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 150%;
  top: 0;
  left: 0;
  background-color: var(--color-fur);
  background-color: var(--color, var(--color-fur));
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}

.leg-inner {
  position: absolute;
  height: 65%;
  width: 100%;
  top: calc(100%);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}
.leg-inner:before {
  content: "";
  width: 45px;
  height: 45px;
  background-color: var(--color);
  position: absolute;
  border-radius: 50%;
  top: -24px;
  left: 0px;
}
.leg-inner > .fur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.leg-inner > .fur:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 150%;
  top: 0;
  left: 0;
  background-color: var(--color);
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.dog>*,
.dog>*:before,
.dog>*:after {
  box-sizing: border-box;
  position: relative;
}
.loader {
    position: absolute;
    width: 75px;
    height: 100px;
    top:calc(50% - 50px);
    left: calc(50% - 37px);
}
.loader__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: #be6adf;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, .2);
}
.loader__bar:nth-child(1) {
    left: 0px;
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
    -webkit-animation: barUp1 4s infinite;
            animation: barUp1 4s infinite;
}
.loader__bar:nth-child(2) {
    left: 15px;
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
    -webkit-animation: barUp2 4s infinite;
            animation: barUp2 4s infinite;
}
.loader__bar:nth-child(3) {
    left: 30px;
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
    -webkit-animation: barUp3 4s infinite;
            animation: barUp3 4s infinite;
}
.loader__bar:nth-child(4) {
    left: 45px;
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
    -webkit-animation: barUp4 4s infinite;
            animation: barUp4 4s infinite;
}
.loader__bar:nth-child(5) {
    left: 60px;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-animation: barUp5 4s infinite;
            animation: barUp5 4s infinite;
}
.loader__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: #be6adf;
    border-radius: 50%;
    -webkit-animation: ball 4s infinite;
            animation: ball 4s infinite;
}
@-webkit-keyframes ball {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
    5% {
        -webkit-transform: translate(8px, -14px);
                transform: translate(8px, -14px);
   }
    10% {
        -webkit-transform: translate(15px, -10px);
                transform: translate(15px, -10px);
   }
    17% {
        -webkit-transform: translate(23px, -24px);
                transform: translate(23px, -24px);
   }
    20% {
        -webkit-transform: translate(30px, -20px);
                transform: translate(30px, -20px);
   }
    27% {
        -webkit-transform: translate(38px, -34px);
                transform: translate(38px, -34px);
   }
    30% {
        -webkit-transform: translate(45px, -30px);
                transform: translate(45px, -30px);
   }
    37% {
        -webkit-transform: translate(53px, -44px);
                transform: translate(53px, -44px);
   }
    40% {
        -webkit-transform: translate(60px, -40px);
                transform: translate(60px, -40px);
   }
    50% {
        -webkit-transform: translate(60px, 0);
                transform: translate(60px, 0);
   }
    57% {
        -webkit-transform: translate(53px, -14px);
                transform: translate(53px, -14px);
   }
    60% {
        -webkit-transform: translate(45px, -10px);
                transform: translate(45px, -10px);
   }
    67% {
        -webkit-transform: translate(37px, -24px);
                transform: translate(37px, -24px);
   }
    70% {
        -webkit-transform: translate(30px, -20px);
                transform: translate(30px, -20px);
   }
    77% {
        -webkit-transform: translate(22px, -34px);
                transform: translate(22px, -34px);
   }
    80% {
        -webkit-transform: translate(15px, -30px);
                transform: translate(15px, -30px);
   }
    87% {
        -webkit-transform: translate(7px, -44px);
                transform: translate(7px, -44px);
   }
    90% {
        -webkit-transform: translate(0, -40px);
                transform: translate(0, -40px);
   }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
}
@keyframes ball {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
    5% {
        -webkit-transform: translate(8px, -14px);
                transform: translate(8px, -14px);
   }
    10% {
        -webkit-transform: translate(15px, -10px);
                transform: translate(15px, -10px);
   }
    17% {
        -webkit-transform: translate(23px, -24px);
                transform: translate(23px, -24px);
   }
    20% {
        -webkit-transform: translate(30px, -20px);
                transform: translate(30px, -20px);
   }
    27% {
        -webkit-transform: translate(38px, -34px);
                transform: translate(38px, -34px);
   }
    30% {
        -webkit-transform: translate(45px, -30px);
                transform: translate(45px, -30px);
   }
    37% {
        -webkit-transform: translate(53px, -44px);
                transform: translate(53px, -44px);
   }
    40% {
        -webkit-transform: translate(60px, -40px);
                transform: translate(60px, -40px);
   }
    50% {
        -webkit-transform: translate(60px, 0);
                transform: translate(60px, 0);
   }
    57% {
        -webkit-transform: translate(53px, -14px);
                transform: translate(53px, -14px);
   }
    60% {
        -webkit-transform: translate(45px, -10px);
                transform: translate(45px, -10px);
   }
    67% {
        -webkit-transform: translate(37px, -24px);
                transform: translate(37px, -24px);
   }
    70% {
        -webkit-transform: translate(30px, -20px);
                transform: translate(30px, -20px);
   }
    77% {
        -webkit-transform: translate(22px, -34px);
                transform: translate(22px, -34px);
   }
    80% {
        -webkit-transform: translate(15px, -30px);
                transform: translate(15px, -30px);
   }
    87% {
        -webkit-transform: translate(7px, -44px);
                transform: translate(7px, -44px);
   }
    90% {
        -webkit-transform: translate(0, -40px);
                transform: translate(0, -40px);
   }
    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
}
@-webkit-keyframes barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    40% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    50% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    90% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    100% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
}
@keyframes barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    40% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    50% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    90% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    100% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
}
@-webkit-keyframes barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    40% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    50% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    90% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    100% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
}
@keyframes barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    40% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    50% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    90% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    100% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
}
@-webkit-keyframes barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
                transform: scale(1, 0.6);
   }
    100% {
        -webkit-transform: scale(1, 0.6);
                transform: scale(1, 0.6);
   }
}
@keyframes barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
                transform: scale(1, 0.6);
   }
    100% {
        -webkit-transform: scale(1, 0.6);
                transform: scale(1, 0.6);
   }
}
@-webkit-keyframes barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    40% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    50% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    90% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    100% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
}
@keyframes barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    40% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
    50% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    90% {
        -webkit-transform: scale(1, 0.4);
                transform: scale(1, 0.4);
   }
    100% {
        -webkit-transform: scale(1, 0.8);
                transform: scale(1, 0.8);
   }
}
@-webkit-keyframes barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    40% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    50% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    90% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
}
@keyframes barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    40% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
    50% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    90% {
        -webkit-transform: scale(1, 0.2);
                transform: scale(1, 0.2);
   }
    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
   }
}

.social {
  width: 40px;
  position: fixed;
  top: calc(50% - 61px);
  bottom: auto;
  left: 40px;
  right: auto;
  z-index: 10;
  color: whitesmoke;
  height: 102px;
}
.social ul {
  list-style-type: none;
}
.social a {
  margin: 0px auto;
  color: whitesmoke;
}
.social a:hover {
  color: #be6adf;
  position: relative;
  top: -2px;
}
.social li {
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
}
.social ul::before {
  background-color: #be6adf;
  content: "";
  width: 2px;
  height: 30px;
  margin: 5px auto;
}
.social ul::after {
  background-color: #be6adf;
  content: "";
  width: 2px;
  height: 30px;
  margin: 5px auto;
}

.socialFooter{
  -webkit-justify-content: center;
          justify-content: center;
  display: none;
}
.socialFooter a
{
  color: whitesmoke;
}
.socialFooter ul {
  list-style-type: none;
  width: 200px;
  margin-left: calc(50% - 100px);
}
.socialFooter li {
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  display: inline;
}
.socialFooter ul::before {
  background-color: #be6adf;
  content: "";
  width: 30px;
  height: 2px;
  margin: auto 5px;
}
.socialFooter ul::after {
  background-color: #be6adf;
  content: "";
  width: 30px;
  height: 2px;
  margin: auto 5px;
}
@media (max-width: 767px) {
    .social
    {
      display: none;
    }
    .socialFooter
    {
      display: block;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

